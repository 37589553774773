import React from "react";
import { useSelector } from "react-redux";
import { NotFound } from "./routing";
import { CheckAccountPermissionFunction } from "./CheckPermissionFunction";

const checkForPermission = (userPermissions, reqPermissions) => {
    let check = false;

    if (!Array.isArray(reqPermissions)) {
        return true;
    }

    reqPermissions.forEach((requiredPermission) => {
        let permission_module = requiredPermission.split(".")[0];
        let permission_action = requiredPermission.split(".")[1];
        userPermissions.forEach((userPermission) => {
            if (permission_module === userPermission["module"] && permission_action === userPermission["action"]) {
                check = true;
            }
        });
    });

    return check;
};

const CheckPermissions = (props) => {
    const users = useSelector((state) => state.users);
    if (users.user == null) {
        return null;
    }
    if (props.requiredPermission.length === 0) {
        return props.children;
    }
    if (checkForPermission(users.user.permissions, props.requiredPermission)) {
        console.log(props.requiredPermission);
        return props.children;
    }
    if (props.showNotFound) {
        return <NotFound />;
    }
    return null;
};

const CheckAccountPermissions = (props) => {
    const users = useSelector((state) => state.users);
    if (users.user == null) {
        return null;
    }
    if (props.requiredPermission.length === 0) {
        return props.children;
    }
    if (CheckAccountPermissionFunction(props.requiredPermission)) {
        console.log(props.requiredPermission);
        return props.children;
    }
    if (props.showNotFound) {
        return <NotFound />;
    }
    return null;
};

export default CheckPermissions;
export { CheckAccountPermissions, checkForPermission };
