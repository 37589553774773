import React from "react";
import { useSelector } from "react-redux";
const CheckPermissionFunction = (requiredPermissions, accountLevel = false) => {
    const users = useSelector((state) => state.users);
    let userPermissions = users.user.permissions;
    if (accountLevel) {
        userPermissions = users.user.account_permissions;
    }
    let check = false;
    if (requiredPermissions === undefined) {
        return true;
    }
    if (requiredPermissions.length === 0) {
        return true;
    }
    requiredPermissions.forEach((requiredPermission) => {
        let permission_module = requiredPermission.split(".")[0];
        let permission_action = requiredPermission.split(".")[1];
        userPermissions.forEach((userPermission) => {
            if (permission_module === userPermission["module"] && permission_action === userPermission["action"]) {
                check = true;
            }
        });
    });
    return check;
};

const CheckAccountPermissionFunction = (requiredPermissions) => {
    const users = useSelector((state) => state.users);
    const userPermissions = users.user.account_permissions;
    let check = false;
    console.log(requiredPermissions);
    if (requiredPermissions === undefined) {
        return true;
    }
    if (requiredPermissions.length === 0) {
        return true;
    }
    requiredPermissions.forEach((requiredPermission) => {
        let permission_module = requiredPermission.split(".")[0];
        let permission_action = requiredPermission.split(".")[1];
        userPermissions.forEach((userPermission) => {
            if (permission_module === userPermission["module"] && permission_action === userPermission["action"]) {
                check = true;
            }
        });
    });
    return check;
};

export default CheckPermissionFunction;
export { CheckAccountPermissionFunction };
