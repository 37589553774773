/**
 * React component that handles the visualization and manipulation of an orchestration flow.
 * It utilizes ReactFlow for rendering and managing the orchestration nodes and edges.
 * Provides functionality for adding, deleting, and editing nodes and edges, and also includes
 * features for saving and restoring the orchestration data. Additionally, it integrates with various
 * external libraries and modules to enable a seamless user experience.
 *
 * @param {Object} orchestrationData - Data related to the orchestration being displayed.
 * @param {string} mode - The mode in which the component is being used. Can be "EDIT" or some other value.
 * @returns {JSX.Element} A React component representing the orchestration visualization and editing interface.
 */

import React, { useState, useRef, useCallback } from "react";
import ReactFlow, {
    ReactFlowProvider,
    addEdge,
    useNodesState,
    useEdgesState,
    Controls,
    Background,
    ControlButton,
    useReactFlow,
    MarkerType,
    useNodes,
    MiniMap,
} from "reactflow";
import {
    StartNode,
    EndNode,
    DatasourceNode,
    ReverseConnectorNode,
    SQLTransformationNode,
    DecisionNode,
    NodePicker,
    DBTTransformationNode,
    PowerBINode,
    TablueNode,
    DelayNode,
    DBTCloudNode,
    AwsLambda
} from "./Nodes";
import "reactflow/dist/style.css";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import FlowToolbar from "./FlowToolbar";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { Grid } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { DeleteFilled } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import useApi from "hooks/useApi";
import flowConfig from "./common/FlowConfig";
import Paper from "@mui/material/Paper";
import { SET_ORCHESTRATION_MODE, SET_ADDING_NODES_TO_CANVAS } from "redux/actionTypes/actionTypes";
import { HttpErrorHandler } from "utils/ErrorHandlers/HttpErrorHandler";
import EdgeWithInput from "./Edges/EdgeWithInput";

const modeToHeightMap = {
    EDIT: "calc(100vh - 210px)",
    RUNS: "60vh",
    ADD: "calc(100vh - 150px)",
};

const nodeTypes = {
    startNode: StartNode,
    endNode: EndNode,
    datasourceNode: DatasourceNode,
    reverseConnectorNode: ReverseConnectorNode,
    sqlTransformationNode: SQLTransformationNode,
    decisionNode: DecisionNode,
    nodePicker: NodePicker,
    dbtTransformationNode: DBTTransformationNode,
    dbtCloudNode:DBTCloudNode,
    powerBIRefreshNode: PowerBINode,
    tableauRefreshNode: TablueNode,
    delayNode: DelayNode,
    awsLambdaFunction: AwsLambda,
};

const edgeTypes = {
    edgeWithInput: EdgeWithInput,
};

const statusToColorMap = {
    COMPLETED: "#23BE82",
    FAILED: "#F77070",
    RUNNING: "#FD9E74",
    "PARTIAL SUCCESS": "#548EEA",
    TRUNCATED: "#F77070",
    CANCELLED: "#F77070",
};

export const nodesData = [
    {
        category: "DataChannel Nodes",
        nodes: [
            {
                name: "Datasource",
                slug: "datasourceNode",
                id: 1,
            },
            {
                name: "SQL Transformation",
                slug: "sqlTransformationNode",
                id: 2,
            },
            {
                name: "DBT Transformation",
                slug: "dbtTransformationNode",
                id: 3,
            },
            {
                name: "DBT Cloud",
                slug: "dbtCloudNode",
                id: 9,
            },
            {
                name: "Reverse Connector",
                slug: "reverseConnectorNode",
                id: 4,
            },
        ],
    },
    {
        category: "Action Nodes",
        nodes: [
            {
                name: "Decision Node",
                slug: "decisionNode",
                id: 5,
            },
            {
                name: "Power Bi Refresh Node",
                slug: "powerBIRefreshNode",
                id: 6,
            },
            {
                name: "Tableau Node",
                slug: "tableauRefreshNode",
                id: 7,
            },
            {
                name: "Delay Node",
                slug: "delayNode",
                id: 8,
            },
            {
                name: "Aws Lambda ",
                slug: "awsLambdaFunction",
                id: 9,
            },

        ],
    },
];

const initialNodes = [
    {
        id: "start",
        type: "startNode",
        position: { x: 200, y: 50 },
        data: {
            borderColor: flowConfig.defaultNodeBorderColor,
        },
    },
    {
        id: "nodePicker",
        type: "nodePicker",
        position: { x: 450, y: 50 },
        data: {
            borderColor: flowConfig.defaultNodeBorderColor,
        },
    },
];

const initialEdges = [
    {
        id: "1",
        source: "start",
        target: "nodePicker",
        animated: true,
        type: "bezier",
        markerEnd: { type: "arrowclosed" },
    },
];

/**
 * styles for minimap
 */
const minimapStyle = {
    height: 120,
    borderRadius: 8,
    border: "1px solid #e3e3e3",
};

/**
 * generated uuid for each new node
 * @returns uuid
 */
const getId = () => uuidv4();

/**
 * Fills the orchestration flow with data from the given orchestrationData.
 *
 * @param {Object} props - Props object containing orchestration data.
 * @returns {null} Returns null after filling the orchestration flow.
 */
const FillFlow = ({ orchestrationData, mode, tasksRunData }) => {
    const { setViewport, setNodes, setEdges, fitView } = useReactFlow();
    const dispatch = useDispatch();
    const nodes = useNodes();

    const onRestore = () => {
        const flow = orchestrationData.configuration;
        if (flow) {
            if (mode === "EDIT") {
                dispatch({ type: SET_ADDING_NODES_TO_CANVAS, payload: true });
                const { x = 0, y = 0, zoom = 1 } = flow.viewport;
                setNodes(flow.nodes || []);
                setEdges(flow.edges || []);
                setViewport({ x, y, zoom });
                dispatch({ type: SET_ADDING_NODES_TO_CANVAS, payload: false });
            }
        }
        if (mode === "RUNS") {
            dispatch({ type: SET_ADDING_NODES_TO_CANVAS, payload: true });
            handleFillRunsData(flow.nodes, flow.edges);
        }
    };

    const handleFillRunsData = (nodes, edges) => {
        let nodesList = JSON.parse(JSON.stringify(nodes));
        let edgesList = edges;
        tasksRunData.forEach((taskRun) => {
            nodesList.forEach((node) => {
                if (node.id === taskRun.node_id) {
                    node.data.status = taskRun.state;
                    node.data.errorMessage = taskRun.state;
                    node.data.stateMessage = taskRun.state === "COMPLETED" ? "SUCCESS" : taskRun.state;
                    node.data.isNodeToolbarOpen = false;
                    if (statusToColorMap[taskRun.state]) {
                        node.data.borderColor = statusToColorMap[taskRun.state];
                    }
                }
                if (node.id === "start") {
                    node.data.borderColor = statusToColorMap["COMPLETED"];
                }
            });
            edgesList.forEach((edge) => {
                if (edge.target === taskRun.node_id) {
                    if (statusToColorMap[taskRun.state]) {
                        edge.style = { stroke: statusToColorMap[taskRun.state] };
                    }
                }
            });
        });
        setNodes(nodesList);
        setEdges(edgesList);
        // dispatch({ type: SET_ADDING_NODES_TO_CANVAS, payload: false });
    };

    React.useEffect(() => {
        setTimeout(() => {
            onRestore();
        }, 50);
    }, [orchestrationData, tasksRunData]);

    React.useEffect(() => {
        console.log(nodes);
    }, [nodes]);

    return null;
};

/**
 * React component that handles the visualization and manipulation of an orchestration flow.
 * @param {Object} orchestrationData - Data related to the orchestration being displayed.
 * @param {string} mode - The mode in which the component is being used. Can be "EDIT" or some other value.
 * @returns {JSX.Element} A React component representing the orchestration visualization and editing interface.
 */
const Orchestration = ({ orchestrationData, tasksRunData, mode = "ADD" }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const reactFlowWrapper = useRef(null);

    const [nameError, setNameError] = React.useState(null);
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const [, addingOrchestration, addOrchestration] = useApi();

    /**
     * Toggle Side navigation bar
     */
    React.useEffect(() => {
        dispatch({ type: "TOGGLE_SIDENAV", payload: false });
    }, []);

    /**
     * useEffect to add mode to redux store for
     * the nodes downstream to take actions on
     * basis of mode
     */
    React.useEffect(() => {
        dispatch({ type: SET_ORCHESTRATION_MODE, payload: mode });
    }, [mode]);

    /**
     * Event handler for connecting nodes with edges.
     *
     * @param {Object} params - Parameters for connecting the nodes.
     */
    const onConnect = useCallback((params) => {
        console.log(params);
        return setEdges((eds) =>
            addEdge(
                {
                    ...params,
                    markerEnd: {
                        type: MarkerType.ArrowClosed,
                    },
                    type: params.source === "start" ? "bezier" : "edgeWithInput",
                    edgeStatus: "Success",
                },
                eds
            )
        );
    }, []);

    /**
     * Event handler for handling the drag-over event.
     *
     * @param {Object} event - The drag-over event object.
     */
    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
    }, []);

    /**
     * Event handler for handling the drop event.
     *
     * @param {Object} event - The drop event object.
     */
    const onDrop = useCallback(
        (event) => {
            event.preventDefault();

            const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
            const type = event.dataTransfer.getData("application/reactflow");

            // check if the dropped element is valid
            if (typeof type === "undefined" || !type) {
                return;
            }

            const position = reactFlowInstance.project({
                x: event.clientX - reactFlowBounds.left,
                y: event.clientY - reactFlowBounds.top,
            });
            const newNode = {
                id: getId(),
                type,
                position,
                data: {
                    isNodeToolbarOpen: false,
                    configration: {},
                    borderColor: flowConfig.defaultNodeBorderColor,
                },
            };
            setNodes((nds) => nds.concat(newNode));
        },
        [reactFlowInstance]
    );

    /**
     * Deletes the selected edge from the flow.
     */
    const deleteEdge = () => {
        setEdges((edges) => edges.filter((edge) => !edge.selected));
    };

    /**
     * Handles the save action for the orchestration.
     *
     * @param {Object} schedule - The scheduling object for the orchestration.
     * @param {string} label - The label for the orchestration.
     */
    const handleSave = async (schedule, label) => {
        let data = {
            ...schedule,
            configuration: reactFlowInstance.toObject(),
            label: label,
        };
        if (mode === "EDIT") {
            const res = await addOrchestration({
                module: "orchestration",
                method: "update",
                successToast: "Orcehstration updated successfully.",
                apiData: {
                    data: data,
                    orchestrationId: orchestrationData.id,
                },
                returnResult: true,
            });
            if (res?.status_code === 422) {
                setNameError(res?.data?.data?.message);
                HttpErrorHandler(dispatch, res?.data?.data?.message);
                return;
            }
            navigate("/orchestrations");
            return;
        }
        const res = await addOrchestration({
            module: "orchestration",
            method: "add",
            successToast: "Orcehstration added successfully.",
            apiData: {
                data: data,
            },
            returnResult: true,
        });
        if (res?.status_code === 422) {
            setNameError(res?.data?.data?.message);
            HttpErrorHandler(dispatch, res?.data?.data?.message);
            return;
        }
        navigate("/orchestrations");
    };

    /**
     * Handler for graph click
     * Resets graph select states
     * @param {*} event
     */
    const handleGraphClick = (event) => {
        // Check if the clicked element is the graph itself, not a node or edge
        if (mode !== "RUNS") {
            if (event?.target?.classList?.contains("react-flow__pane")) {
                console.log(event);
                setNodes((nodes) =>
                    nodes.map((node) => ({
                        ...node,
                        data: {
                            ...node.data,
                            isNodeToolbarOpen: false,
                            status: undefined,
                            borderColor: flowConfig.defaultNodeBorderColor,
                        },
                    }))
                );
                setEdges((edges) =>
                    edges.map((edge) => {
                        delete edge.style;
                        return edge;
                    })
                );
            }
        }
    };

    return (
        <>
            {mode === "ADD" && (
                <Grid container style={{ marginLeft: 10, marginBottom: 10 }}>
                    <Grid item xs={12}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link color="inherit" to="/orchestrations">
                                <Typography className="grayText" variant="subtitle1">
                                    Orchestrations
                                </Typography>
                            </Link>
                            <Typography className="boldText" variant="subtitle1">
                                Add
                            </Typography>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
            )}
            <Paper
                elevation={3}
                style={{ marginLeft: 10, marginRight: 10, marginBottom: 15, position: "relative" }}
                id="drawer-container"
            >
                <div
                    style={{
                        height: modeToHeightMap[mode] !== undefined ? modeToHeightMap[mode] : modeToHeightMap["ADD"],
                        overflowY: "scroll",
                        padding: 10,
                    }}
                >
                    <div className="dndflow">
                        <ReactFlowProvider>
                            {(mode === "EDIT" || mode === "RUNS") && (
                                <FillFlow orchestrationData={orchestrationData} mode={mode} tasksRunData={tasksRunData} />
                            )}
                            <Grid container>
                                <Grid item xs={12}>
                                    {mode !== "RUNS" && (
                                        <FlowToolbar
                                            onSave={handleSave}
                                            isSaving={addingOrchestration}
                                            orchestrationName={orchestrationData?.label}
                                            orchestrationData={orchestrationData}
                                            mode={mode}
                                            nodesData={nodesData}
                                            nameError={nameError}
                                            setNameError={setNameError}
                                        />
                                    )}
                                </Grid>
                                <Grid xs={12} className="dndflow">
                                    {/* {mode !== "RUNS" && <Sidebar nodesData={nodesData} />} */}
                                    <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                                        <ReactFlow
                                            nodes={nodes}
                                            edges={edges}
                                            onNodesChange={onNodesChange}
                                            onEdgesChange={onEdgesChange}
                                            onConnect={onConnect}
                                            onInit={setReactFlowInstance}
                                            onDrop={onDrop}
                                            onDragOver={onDragOver}
                                            onClick={handleGraphClick}
                                            nodeTypes={nodeTypes}
                                            edgeTypes={edgeTypes}
                                            nodesConnectable={mode !== "RUNS"}
                                            nodesDraggable={mode !== "RUNS"}
                                            elementsSelectable={mode !== "RUNS"}
                                            edgesUpdatable
                                            defaultViewport={{ x: 0, y: 0, zoom: 0.9 }}
                                        >
                                            <MiniMap style={minimapStyle} zoomable pannable position="top-right" />
                                            <Controls
                                                position={mode === "RUNS" ? "bottom-left" : "top-left"}
                                                showInteractive={mode !== "RUNS"}
                                            >
                                                {mode !== "RUNS" && (
                                                    <ControlButton onClick={deleteEdge} title="Delete Edge">
                                                        <DeleteFilled />
                                                    </ControlButton>
                                                )}
                                            </Controls>
                                            <Background />
                                        </ReactFlow>
                                    </div>
                                </Grid>
                            </Grid>
                        </ReactFlowProvider>
                    </div>
                </div>
            </Paper>
        </>
    );
};

export default Orchestration;
