import { Typography, Grid, Button, Box, Paper, TextField, Tooltip } from "@mui/material";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";

import edit from "../../../assets/images/DataSourceDetails/edit.svg";

import { useDispatch, useSelector } from "react-redux";
import { fetchCredentials, fetchDatasource } from "../../../redux/actions/dataSourceActions";
import { fetchCredentials as fetchCredentialsForDatasource } from "../../../redux/actions/usersAction";
import CircularProgress from "@mui/material/CircularProgress";
import RefreshIcon from "@mui/icons-material/Refresh";
import config from "../../../constants/config";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { motion } from "framer-motion";
import { deleteCredential } from "services/Api/api";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import { HttpErrorHandler } from "utils/ErrorHandlers/HttpErrorHandler";
import successSnackbar from "utils/successSnackbar";
import CheckPermissions from "services/CheckPermissions";

const useStyles = makeStyles((theme) => ({
    credBtn: {
        width: 24,
        height: 24,
        borderRadius: "50%",
        marginTop: 0,
        padding: "unset",
        marginBottom: 5,
        marginRight: 5,
    },
    hr: {
        backgroundColor: "#FD9E74",
        border: "2px solid #FD9E74",
        width: 20,
        marginTop: 11,
        marginBottom: 15,
        position: "relative",
        left: 0,
    },
    redText: {
        fontSize: 10,
        color: "#FFFFFF",
        backgroundColor: "#F77070",
        borderRadius: 7,
        padding: "2px 5px",
        marginRight: 5,
    },
    credContainer: {
        marginTop: 10,
    },
    destination: {
        marginTop: 48,
        transition: "border 1s",
    },
    destinationBox: {
        marginTop: 109,
    },
    warning: {
        color: "#F77070",
    },
    alertIcon: {
        marginRight: 10,
        marginBottom: -2,
    },
    showBorder: {
        border: "2px solid #FD9567",
    },
    noBorder: {
        border: "2px solid transparent",
    },
    disabled: {
        cursor: "not-allowed",
        color: "#c0c0c0",
    },
    enabled: {
        color: "rgb(159 159 159)",
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));
const Credentials = (props) => {
    const classes = useStyles();
    const { dataSourceSlug, reverseConnectorSlug } = useParams();
    const slug = dataSourceSlug !== undefined ? dataSourceSlug : props.datasourceSlug;
    const [credDeleting, setCredDeleting] = React.useState(null);
    const [selectedCredential, setSelectedCredential] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const dataSource = useSelector((state) => state.dataSource);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (props.selectedDatasource === undefined) {
            if (slug !== undefined) {
                dispatch(fetchCredentials(slug));
                if (props.reportData) {
                    props.setSelectedCredential({
                        id: props.reportData.credential_id,
                    });
                }
            }
        }
    }, []);

    const handleAddCredentials = () => {
        if (props.selectedDatasource === undefined) {
            var win = window.open(
                `${config.backendUrl}datasources/${slug}/credentials/add?token=${Cookies.get("accessToken")}`,
                "auth_popup",
                "width=1300,height=1000"
            );
            var timer = setInterval(function () {
                if (win.closed) {
                    clearInterval(timer);
                    dispatch(fetchCredentials(slug));
                }
            }, 1000);
        } else {
            var win = window.open(
                `${config.backendUrl}datasources/${props.selectedDatasource.slug}/credentials/add?token=${Cookies.get(
                    "accessToken"
                )}`,
                "auth_popup",
                "width=1000,height=800"
            );
            var timer = setInterval(function () {
                if (win.closed) {
                    clearInterval(timer);
                    dispatch(fetchCredentialsForDatasource(props.selectedDatasource.slug));
                }
            }, 1000);
        }
    };
    const handleRefreshCredentials = () => {
        if (props.selectedDatasource === undefined) dispatch(fetchCredentials(slug));
        else dispatch(fetchCredentialsForDatasource(props.selectedDatasource.slug));
    };

    const handleEditCredential = (credId) => {
        if (props.selectedDatasource === undefined) {
            var win = window.open(
                `${config.backendUrl}datasources/${slug}/credentials/${credId}?token=${Cookies.get("accessToken")}`,
                "auth_popup",
                "width=1000,height=800"
            );
            var timer = setInterval(function () {
                if (win.closed) {
                    clearInterval(timer);
                    dispatch(fetchCredentials(slug));
                }
            }, 1000);
        } else {
            var win = window.open(
                `${config.backendUrl}datasources/${props.selectedDatasource.slug}/credentials/${credId}?token=${Cookies.get(
                    "accessToken"
                )}`,
                "auth_popup",
                "width=1000,height=800"
            );
            var timer = setInterval(function () {
                if (win.closed) {
                    clearInterval(timer);
                    dispatch(fetchCredentials(props.selectedDatasource.slug));
                }
            }, 1000);
        }
    };

    if (dataSource.credentialsIsLoading) {
        return (
            <Grid container justifyContent="center" alignItems="center">
                <CircularProgress />
            </Grid>
        );
    }

    const handleOpen = (credId) => {
        setSelectedCredential(credId);
        setOpen(true);
    };

    const handleClose = () => {
        setSelectedCredential(null);
        setOpen(false);
    };

    const handleDelete = async () => {
        if (props.selectedDatasource === undefined) {
            handleClose();
            try {
                setCredDeleting(selectedCredential);
                await deleteCredential(slug, selectedCredential);
                dispatch(fetchCredentials(slug));
                setCredDeleting(null);
                successSnackbar(dispatch, "Credential deleted successfully.");
            } catch (err) {
                console.log(err);
                HttpErrorHandler(dispatch, "Could not delete credentials, please try again later.");
                setCredDeleting(null);
            }
        } else {
            handleClose();
            try {
                setCredDeleting(selectedCredential);
                await deleteCredential(props.selectedDatasource.slug, selectedCredential);
                dispatch(fetchCredentials(props.selectedDatasource.slug));
                setCredDeleting(null);
                successSnackbar(dispatch, "Credential deleted successfully.");
            } catch (err) {
                console.log(err);
                HttpErrorHandler(dispatch, "Could not delete credentials, please try again later.");
                setCredDeleting(null);
            }
        }
    };

    return (
        <>
            <Grid xs={12} container justifyContent="space-between">
                <Grid item>
                    <Typography className="size16" variant="h3">
                        Credentials
                    </Typography>
                </Grid>
                <Grid item>
                    <Button className={classes.credBtn} onClick={handleRefreshCredentials} variant="contained" color="primary">
                        <RefreshIcon fontSize="small" />
                    </Button>
                    <CheckPermissions requiredPermission={["credential.create"]}>
                        <Button className={classes.credBtn} onClick={handleAddCredentials} variant="contained" color="primary">
                            +
                        </Button>
                    </CheckPermissions>
                </Grid>
            </Grid>
            {(props.selectedDatasource === undefined ? dataSource.credentials : props.credentails)?.map((el, i) => (
                <CheckPermissions requiredPermission={["credential.view"]}>
                    <Paper
                        key={i}
                        onClick={() => (props.target === "ADD" ? props?.setSelectedCredential(el) : null)}
                        className={clsx(
                            "shadow",
                            props.target === "ADD" ? "hoverable" : null,
                            classes.credContainer,
                            props.target === "ADD" && props.selectedCredential?.id === el.id
                                ? classes.showBorder
                                : classes.noBorder
                        )}
                        elevation={0}
                        component={motion.div}
                        whileHover={{
                            scale: 1.04,
                            // transition: { duration: 1 }
                        }}
                    >
                        <Grid alignItems="center" justifyContent="space-between" container className="padding">
                            <Grid item>
                                <Grid container>
                                    {props.target === "ADD" && props.selectedCredential?.id === el.id ? (
                                        <motion.div initial={{ opacity: 0.4, x: -20 }} animate={{ opacity: 1, x: 0 }}>
                                            <CheckCircleOutlineOutlinedIcon
                                                style={{ marginRight: 10 }}
                                                color="primary"
                                                fontSize="small"
                                            />
                                        </motion.div>
                                    ) : null}

                                    <Typography className="size12 blackText">{el.name}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item>
                                        <Grid container justifyContent="center">
                                            <Typography className={classes.redText}>{el.configured_syncs}</Typography>
                                        </Grid>
                                        <Typography
                                            style={{
                                                fontSize: 8,
                                                color: "#b2b2b2",
                                                position: "relative",
                                                left: -5,
                                            }}
                                        >
                                            syncs
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Grid container justifyContent="center">
                                            <Typography className={classes.redText}>{el.configured_data_pipelines}</Typography>
                                        </Grid>
                                        <Typography
                                            style={{
                                                fontSize: 8,
                                                color: "#b2b2b2",
                                            }}
                                        >
                                            Pipelines
                                        </Typography>
                                    </Grid>
                                    <CheckPermissions requiredPermission={["credential.edit"]}>
                                        <Grid item>
                                            <img
                                                src={edit}
                                                className="hoverable"
                                                alt="icon"
                                                onClick={() => handleEditCredential(el.id)}
                                            />
                                        </Grid>
                                    </CheckPermissions>
                                    {credDeleting === el.id ? (
                                        <CircularProgress size={15} style={{ marginLeft: 10 }} />
                                    ) : el.configured_data_pipelines === 0 && el.configured_syncs === 0 ? (
                                        <CheckPermissions requiredPermission={["credential.delete"]}>
                                            <Grid item>
                                                <DeleteOutlineOutlinedIcon
                                                    // onClick={() => handleDelete(el.id)}
                                                    onClick={() => handleOpen(el.id)}
                                                    className={clsx("hoverable", classes.enabled)}
                                                />
                                            </Grid>
                                        </CheckPermissions>
                                    ) : (
                                        <CheckPermissions requiredPermission={["credential.delete"]}>
                                            <Grid item>
                                                <DeleteOutlineOutlinedIcon className={classes.disabled} />
                                            </Grid>
                                        </CheckPermissions>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </CheckPermissions>
            ))}
            {(props.selectedDatasource === undefined ? dataSource.credentials : props.credentails).length === 0 ? (
                <Grid container style={{ height: 400 }} justifyContent="center" alignItems="center">
                    <Grid item container justifyContent="center">
                        <Grid item>
                            <Typography variant="subtitle1" className="size16">
                                No credentials available.
                            </Typography>
                        </Grid>
                        <CheckPermissions requiredPermission={["credential.create"]}>
                        <Grid item xs={12} container justifyContent="center">
                            <Button onClick={handleAddCredentials} size="small" color="primary" variant="contained">
                                Add a Credential
                            </Button>
                        </Grid>
                        </CheckPermissions>
                    </Grid>
                </Grid>
            ) : null}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                components={Backdrop}
                componentsProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <Typography variant="h5">Are you sure you wanna delete this credential?</Typography>
                        <Grid container justifyContent="center">
                            <Button onClick={handleDelete} variant="contained" color="primary" style={{ marginRight: 10 }}>
                                Yes
                            </Button>
                            <Button onClick={handleClose} variant="outlined" color="primary">
                                No
                            </Button>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </>
    );
};

export default Credentials;
