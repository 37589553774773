import React from "react";
import useApi from "hooks/useApi";
import { Autocomplete, CircularProgress, TextField, Typography, Grid, Button } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import NodeNotifications from "../common/NodeNotifications";

const filterOptions = (options, state) => {
    const newOptions = [...options].filter((op) => op.name.toLowerCase().includes(state.inputValue.toLowerCase()));
    return newOptions;
};

export default function SelectDataset({ onNodeExecutionConfigrationChange, onFinish, configuration }) {
    const [, dynamicDataLoading, getDynamicParamData] = useApi();

    // quick fix for setting dataset to empty value in case workbooks are reloaded
    // but not for the first time they are loaded
    const [compLoadedOnce, setCompLoadedOnce] = React.useState(false);
    const [datasets, setDatasets] = React.useState([]);
    const [workspaces, setWorkspaces] = React.useState([]);

    const getAllWorkbooks = async () => {
        const userData = {
            credential_id: configuration?.credential?.value?.id,
            group_id: configuration?.group?.value?.id,
        };
        let data = await getDynamicParamData({
            module: "orchestration",
            method: "getOrcehstrationDynamicParamData",
            apiData: {
                node_slug: "powerBIRefreshNode",
                property_name: "dataset_id",
                data: userData,
            },
            returnResult: true,
        });
        if (compLoadedOnce) onNodeExecutionConfigrationChange("dataset", null, "=");
        setDatasets(data);
        setCompLoadedOnce(true);
    };
    const getAllWorkspaces = async () => {
        const userData = {
            credential_id: configuration?.credential?.value?.id,
        };
        let data = await getDynamicParamData({
            module: "orchestration",
            method: "getOrcehstrationDynamicParamData",
            apiData: {
                node_slug: "powerBIRefreshNode",
                property_name: "group_id",
                data: userData,
            },
            returnResult: true,
        });
        setWorkspaces(data);
    };
    React.useEffect(() => {
        if (configuration?.group?.value) {
            getAllWorkbooks();
        }
    }, [configuration?.group?.value]);

    React.useEffect(() => {
        getAllWorkspaces();
    }, []);
    return (
        <>
            {dynamicDataLoading ? (
                <Grid container justifyContent="center">
                    <CircularProgress />
                </Grid>
            ) : (
                <>
                    <Grid style={{ margin: "18px 0px 0px", padding: "15px 15px 0px" }}>
                        <Typography className="blackText size-14 semiBoldText">Select Workspace</Typography>
                        <Autocomplete
                            options={workspaces}
                            filterOptions={filterOptions}
                            isOptionEqualToValue={(option, value) => value.id === option.id}
                            getOptionLabel={(option) => option.name}
                            getOptionKey={(option) => option.id}
                            onChange={(e, newValue, reason) => onNodeExecutionConfigrationChange("group", newValue, "=")}
                            value={configuration?.group?.value}
                            renderInput={(params) => (
                                <TextField {...params} size="small" label="Select Workspace" variant="outlined" />
                            )}
                        />
                    </Grid>
                    {configuration?.group?.value && (
                        <Grid style={{ margin: "18px 0px 0px", padding: "15px 15px 0px" }}>
                            <Typography className="blackText size-14 semiBoldText">Select DataSet</Typography>
                            <Autocomplete
                                options={datasets}
                                filterOptions={filterOptions}
                                isOptionEqualToValue={(option, value) => value.id === option.id}
                                getOptionLabel={(option) => option.name}
                                getOptionKey={(option) => option.id}
                                onChange={(e, newValue, reason) => onNodeExecutionConfigrationChange("dataset", newValue, "=")}
                                value={configuration?.dataset?.value}
                                renderInput={(params) => (
                                    <TextField {...params} size="small" label="Select DataSet" variant="outlined" />
                                )}
                            />
                        </Grid>
                    )}
                    <NodeNotifications
                        onNodeExecutionConfigrationChange={onNodeExecutionConfigrationChange}
                        value={configuration?.notification_level?.value}
                    />
                    <Grid container justifyContent="center">
                        <Grid item>
                            <Button onClick={onFinish} variant="contained" color="primary">
                                Finish
                            </Button>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
}
