import axios from "config/axios";
import BaseEndpointsClass from "./BaseEndpointsClass";
import Cookies from "js-cookie";

class WarehouseEndpoints extends BaseEndpointsClass {
    constructor() {
        super();
        this.resourceEndpoint = `datawarehouses`;
        this.transformer = null;
    }
    getAll() {
        return axios.get(`${this.resourceEndpoint}`);
    }
    addWarehouse(data) {
        return axios.post(`${this.resourceEndpoint}`, data.apiData.data);
    }
    editWarehouse(data) {
        return axios.post(`${this.resourceEndpoint}/${data.apiData.warehouseId}`, data.apiData.data);
    }
    fetchValidatedSteps(data) {
        return axios.get(`/validation/progress/${data.apiData.validation_job_id}`);
    }
    getValidationJobId(data) {
        if (data.access_token) {
            return axios.post(
                `/validation/type/${data.apiData.validation_type_slug}/subtype/${data.apiData.validation_subtype_slug}/start?token=${data.access_token}`,
                data.apiData.data
            );
        }
        axios.defaults.headers.common["Authorization"] = `Bearer ${Cookies.get("accessToken")}`;
        return axios.post(
            `/validation/type/${data.apiData.validation_type_slug}/subtype/${data.apiData.validation_subtype_slug}/start`,
            data.apiData.data
        );
    }
    fetchStepsForValidation(data) {
        return axios.get(
            `/validation/type/${data.apiData.validation_type_slug}/subtype/${data.apiData.validation_subtype_slug}/steps`
        );
    }
    saveValidatedWarehouse(data) {
        console.log(data);
        return axios.post(
            `/validation/type/${data.apiData.validation_type_slug}/subtype/${data.apiData.validation_subtype_slug}/persist?token=${data.access_token}`,
            data.apiData.data
        );
    }
    bigQueryServiceAccAddtionalData() {
        return axios.post(`/warehouse/google/service_account`);
    }
    addDCManagedbigQuery(data) {
        return axios.post(`/account/${data.apiData.account_slug}/datawarehouses/bigquery/save`, data.apiData.data, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    }
    addBigQueryserviceAccountWarehouse(data) {
        return axios.post(`/datawarehouses/${data.apiData.datawarehouse_slug}/warehouse/service_account/save`, data.apiData.data);
    }
    validateWarehouseName(data) {
        return axios.post("/datawarehouses/name/validate", data.apiData.data);
    }
    dcManagedWarehouseEdit(data) {
        return axios.patch(
            `/account/${data.apiData.account_slug}/datawarehouses/${data.apiData.warehouseId}/`,
            data.apiData.data
        );
    }
}

export default WarehouseEndpoints;
