import React from "react";
import { Grid, Typography, Button, TextField } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { useDispatch, useSelector } from "react-redux";
import { fetchDbtCloudTransformations } from "redux/actions/dbtCloudTransformationsActions";
import CircularProgress from "@mui/material/CircularProgress";
import NoData from "components/common/NoData/NoData";
import Pagination from "@mui/material/Pagination";
import { motion } from "framer-motion";

const SelectTransformation = ({ selectedTransformationId, onTransformationSelect, handleNext }) => {
    const dbtCloudTransformations = useSelector((state) => state.dbtCloudTransformations);
    const dispatch = useDispatch();

    const source_per_page = 10;
    const [page, setPage] = React.useState(1);
    const [search, setSearch] = React.useState("");

    const handleChange = (event, value) => {
        setPage(value);
    };


    const getFilteredResults = (transformations) => {
        return transformations.filter((el) => el.pipeline_name.toLowerCase().includes(search.toLowerCase()));
    };

    React.useEffect(() => {
        dispatch(fetchDbtCloudTransformations());
    }, []);

    if (dbtCloudTransformations.isLoading) {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ height: "85vh" }}>
                <CircularProgress />
            </Grid>
        );
    }

    if (dbtCloudTransformations.dbtCloudTransformations.length === 0) {
        return <NoData link="/transformations/dbt-cloud/add" showButton={true} height={"85vh"} componentType="transformation" />;
    }
    return (
        <>
            <Grid container>
                <Grid style={{ margin: "10px 20px" }} item xs={12} container>
                    <Grid item xs={6}>
                        <Typography className="blackText boldText size-16">DBT Cloud Name</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className="blackText boldText size-16">Status</Typography>
                    </Grid>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        onChange={(e) => setSearch(e.target.value)}
                        label="search"
                    />
                </Grid>
                {getFilteredResults(dbtCloudTransformations.dbtCloudTransformations)
                    .slice(source_per_page * (page - 1), source_per_page * page)
                    .map((transformation) => (
                        <Grid
                            key={transformation.id}
                            component={motion.div}
                            whileHover={{ scale: 1.02, border: "1px solid #FD9567", cursor: "pointer" }}
                            item
                            xs={12}
                            container
                            style={{
                                margin: "5px 20px",
                                borderRadius: 10,
                                boxShadow:
                                    "0px 0.266px 3.72406px 0px rgba(12, 44, 108, 0.08), 0px 0.89345px 12.50833px 0px rgba(12, 44, 108, 0.05), 0px 4px 56px 0px rgba(12, 44, 108, 0.03)",
                                border: transformation.id === selectedTransformationId ? "1px solid #FD9567" : null,
                            }}
                            onClick={() => onTransformationSelect(transformation)}
                        >
                            <Grid container style={{ padding: 10 }}>
                                <Grid item xs={6}>
                                    <Typography style={{ wordBreak: "break-word" }}>{transformation.pipeline_name}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>{transformation?.status}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                <Grid container justifyContent="center" style={{ margin: "30px 0px" }}>
                    <Pagination
                        count={parseInt(getFilteredResults(dbtCloudTransformations.dbtCloudTransformations).length / source_per_page) + 1}
                        color="primary"
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent="center">
                <Button
                    disabled={selectedTransformationId === undefined || selectedTransformationId === null}
                    style={{ marginLeft: 10 }}
                    color="primary"
                    variant="contained"
                    onClick={handleNext}
                >
                    Next
                </Button>
            </Grid>
        </>
    );
};

export default SelectTransformation;
