import React from "react";
import { Grid, Button, Switch, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CheckPermissions from "services/CheckPermissions";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import clsx from "clsx";
import { Link } from "react-router-dom";
import useApi from "hooks/useApi";
import { fetchDataModels } from "redux/actions/dataModelActions";
import { fetchSegments } from "redux/actions/segmentActions";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
    icon: {
        color: "#A5AEB7",
        marginRight: 4,
        fontSize: 17,
        cursor: "pointer",
        transition: "initial",
        transitionDuration: "0.2s",
        "&:hover": {
            transform: "scale(1.2)",
        },
    },
    font15: {
        fontSize: 15,
    },
    disabled: {
        cursor: "not-allowed",
        color: "#d7d7d7",
    },
}));

export default function TransformationActionButtons({ dataModelId, fontSize, no_of_configured_syncs, isSegment }) {
    const classes = useStyles();
    const [reportLoading, setReportLoading] = React.useState(null);
    const currentModule = isSegment ? "Segment" : "Data Model";
    const dispatch = useDispatch();
    //api calls using useApi hook
    const [, transfomrationIsDeleting, deleteDataModel] = useApi();

    const handleDelete = async (dataModelId) => {
        if (no_of_configured_syncs === 0) {
            if (window.confirm("Are you sure you want to delete this data model?")) {
                setReportLoading(dataModelId);
                await deleteDataModel({
                    module: "dataModels",
                    method: "deleteDataModel",
                    successToast: isSegment ? "Segment deleted successfully" : "Data Model deleted successfully.",
                    apiData: {
                        dataModelId: dataModelId,
                    },
                });
                setReportLoading(null);
                dispatch(fetchDataModels());
                dispatch(fetchSegments());
            } else {
                return;
            }
        }
    };

    return (
        <div>
            <CheckPermissions requiredPermission={["sync.delete"]}>
                <Tooltip
                    title={
                        no_of_configured_syncs === 0
                            ? `Delete ${currentModule}`
                            : "Cannot delete as there are configured syncs using this model."
                    }
                >
                    {transfomrationIsDeleting && reportLoading === dataModelId ? (
                        <CircularProgress size={15} />
                    ) : (
                        <DeleteOutlineOutlinedIcon
                            className={clsx(classes.icon, {
                                [classes.font15]: fontSize !== undefined,
                                [classes.disabled]: no_of_configured_syncs > 0,
                            })}
                            fontSize="small"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleDelete(dataModelId);
                            }}
                        />
                    )}
                </Tooltip>
            </CheckPermissions>
            <CheckPermissions requiredPermission={["sync.edit"]}>
                <Tooltip title={`Edit ${currentModule}`}>
                    <Link to={!isSegment ? `/data-models/edit/${dataModelId}` : `/segments/edit/${dataModelId}`}>
                        <EditOutlinedIcon
                            className={clsx(classes.icon, {
                                [classes.font15]: fontSize !== undefined,
                            })}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            fontSize="small"
                        />
                    </Link>
                </Tooltip>
            </CheckPermissions>
        </div>
    );
}
