import React from "react";
import { HttpErrorHandler } from "utils/ErrorHandlers/HttpErrorHandler";
import { fetchConfiguredSyncs } from "redux/actions/reverseConnectorActions";
import { useDispatch } from "react-redux";
import { enqueueSnackbar as enqueueSnackbarAction, closeSnackbar as closeSnackbarAction } from "redux/actions/NotifierActions";
import Tooltip from "@mui/material/Tooltip";
import CheckPermissions from "services/CheckPermissions";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import QueryBuilderOutlinedIcon from "@mui/icons-material/QueryBuilderOutlined";
import ReorderOutlinedIcon from "@mui/icons-material/ReorderOutlined";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { Link } from "react-router-dom";
import useApi from "hooks/useApi";
import { checkIfExceededLimitForActiveSync } from "services/FreePlanLimitsFunctions";
import { TOGGLE_RESTRICT_POPUP } from "redux/actionTypes/actionTypes";
import { useSelector } from "react-redux";
const closeSnackbar = (dispatch, ...args) => dispatch(closeSnackbarAction(...args));

const useStyles = makeStyles((theme) => ({
    icon: {
        color: "#A5AEB7",
        marginRight: 4,
        fontSize: 17,
        cursor: "pointer",
        transition: "initial",
        transitionDuration: "0.2s",
        "&:hover": {
            transform: "scale(1.2)",
        },
    },
    font15: {
        fontSize: 15,
    },
}));

export default function ConfiguredSyncsActionButtons({ reverseConnectorSlug, configuredSyncId, fontSize }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    // const [loading, setLoading] = React.useState(false);
    const [reportLoading, setReportLoading] = React.useState(null);

    //   //using api hook
    const [, manualRunIsLoading, runSync] = useApi();
    const [, reportDeleting, deleteConfiguredSync] = useApi();
    const reverseConnectors = useSelector((state) => state.reverseConnectors);
    const user = useSelector((state) => state.users.user);

    const handleManualRun = async (configuredSyncId) => {
        if (checkIfExceededLimitForActiveSync(user, reverseConnectors, reverseConnectorSlug)) {
            dispatch({ type: TOGGLE_RESTRICT_POPUP, payload: { show_popup: true, isClosable: true, showUpgradeButtons: true } });
            return;
        }
        setReportLoading(configuredSyncId);
        await runSync({
            module: "reverseConnectors",
            method: "runConfiguredSync",
            successToast: "Configured sync run was successful.",
            apiData: {
                slug: reverseConnectorSlug,
                configuredSyncId: configuredSyncId,
            },
        });
        setReportLoading(null);
    };

    const handleDelete = async (configuredSyncId) => {
        if (window.confirm("Are you sure you want to delete this configured sync?")) {
            setReportLoading(configuredSyncId);
            await deleteConfiguredSync({
                module: "reverseConnectors",
                method: "deleteSync",
                successToast: "Configured sync deleted successfully.",
                apiData: {
                    slug: reverseConnectorSlug,
                    configuredSyncId: configuredSyncId,
                },
            });
            dispatch(fetchConfiguredSyncs(reverseConnectorSlug));
            setReportLoading(null);
        } else {
            return;
        }
    };

    return (
        <Grid container>
            <CheckPermissions requiredPermission={["sync.edit"]}>
                <Tooltip title="Edit Configured Sync">
                    <Link to={`/reverse-connectors/${reverseConnectorSlug}/sync/${configuredSyncId}`}>
                        <EditOutlinedIcon
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            className={clsx(classes.icon, {
                                [classes.font15]: fontSize !== undefined,
                            })}
                            fontSize="small"
                        />
                    </Link>
                </Tooltip>
            </CheckPermissions>
            <CheckPermissions requiredPermission={["sync.run"]}>
                <Tooltip title="Run Configured Sync">
                    {manualRunIsLoading && reportLoading === configuredSyncId ? (
                        <CircularProgress size={15} />
                    ) : (
                        <PlayCircleOutlineIcon
                            className={clsx(classes.icon, {
                                [classes.font15]: fontSize !== undefined,
                            })}
                            fontSize="small"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleManualRun(configuredSyncId);
                            }}
                        />
                    )}
                </Tooltip>
            </CheckPermissions>
            <CheckPermissions requiredPermission={["sync.create"]}>
                <Tooltip title="Copy Data Pipeline">
                    <Link to={`/reverse-connectors/${reverseConnectorSlug}/sync/new?configured_sync_id=${configuredSyncId}`}>
                        <FileCopyOutlinedIcon
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            className={clsx(classes.icon, {
                                [classes.font15]: fontSize !== undefined,
                            })}
                            fontSize="small"
                        />
                    </Link>
                </Tooltip>
            </CheckPermissions>
            {/* 
      
      <CheckPermissions requiredPermission={["report.edit"]}>
        <Tooltip title="Last Run">
          <Link
            to={`/datasource/${datasourceSlug}/detail/editreport/${reportId}?tab=last_run`}
          >
            <QueryBuilderOutlinedIcon
              onClick={(e) => {
                e.stopPropagation();
              }}
              className={clsx(classes.icon, {
                [classes.font15]: fontSize !== undefined,
              })}
              fontSize="small"
            />
          </Link>
        </Tooltip>
      </CheckPermissions>
      <CheckPermissions requiredPermission={["report.edit"]}>
        <Tooltip title="Change History">
          <Link
            to={`/datasource/${datasourceSlug}/detail/editreport/${reportId}?tab=change_history`}
          >
            <ReorderOutlinedIcon
              onClick={(e) => {
                e.stopPropagation();
              }}
              className={clsx(classes.icon, {
                [classes.font15]: fontSize !== undefined,
              })}
              fontSize="small"
            />
          </Link>
        </Tooltip>
      </CheckPermissions>
	   */}
            <CheckPermissions requiredPermission={["sync.delete"]}>
                <Tooltip title="Delete Configured sync">
                    {reportDeleting && reportLoading === configuredSyncId ? (
                        <CircularProgress size={15} />
                    ) : (
                        <DeleteOutlineOutlinedIcon
                            className={clsx(classes.icon, {
                                [classes.font15]: fontSize !== undefined,
                            })}
                            fontSize="small"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleDelete(configuredSyncId);
                            }}
                        />
                    )}
                </Tooltip>
            </CheckPermissions>
        </Grid>
    );
}
