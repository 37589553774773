import {
    SET_DBT_CLOUD_TRANSFORMATIONS,
    SET_DBT_CLOUD_TRANSFORMATIONS_LOADING,
    SET_DBT_CLOUD_CREDENTIALS,
    SET_DBT_CLOUD_CREDENTIALS_LOADING,
} from "../actionTypes/actionTypes";
// import { getTransformations } from "../../services/Api/api"
import { HttpErrorHandler } from "../../utils/ErrorHandlers/HttpErrorHandler";
import { reduxApiWrapper as apiWrapper } from "utils/ReduxApiWrapper";

const fetchDbtCloudTransformations = () => async (dispatch) => {
    apiWrapper(
        dispatch,
        SET_DBT_CLOUD_TRANSFORMATIONS_LOADING,
        SET_DBT_CLOUD_TRANSFORMATIONS,
        {
            module: "dbtCloud",
            method: "getAll",
        },
        HttpErrorHandler
    );
};

const fetchDbtCloudCreds = () => async (dispatch) => {
    apiWrapper(
        dispatch,
        SET_DBT_CLOUD_CREDENTIALS_LOADING,
        SET_DBT_CLOUD_CREDENTIALS,
        {
            module: "dbtCloud",
            method: "getAllCreds",
        },
        HttpErrorHandler
    );
};

export { fetchDbtCloudTransformations, fetchDbtCloudCreds };
