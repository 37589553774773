import React from "react";
import { Grid, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import CheckPermissions from "services/CheckPermissions";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import CardMembershipOutlinedIcon from "@mui/icons-material/CardMembershipOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import clsx from "clsx";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    icon: {
        color: "#A5AEB7",
        transition: "initial",
        transitionDuration: "0.1s",
        marginRight: 4,
        "&:hover": {
            transform: "scale(1.2)",
        },
    },
    font15: {
        fontSize: "15px",
    },
}));

export default function ReverseConnectorActionButtons({ reverseConnectorSlug, fontSize }) {
    const classes = useStyles();
    return (
        <Grid container style={{ minWidth: 100 }}>
            <CheckPermissions requiredPermission={["sync.create"]}>
                <Tooltip style={{ zIndex: 1210 }} title="Add Reverse Connector">
                    <Link to={`/reverse-connectors/${reverseConnectorSlug}/sync/new`}>
                        <div>
                            <AddCircleOutlineIcon
                                className={clsx(classes.icon, { [classes.font15]: fontSize !== undefined })}
                                fontSize="small"
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            />
                        </div>
                    </Link>
                </Tooltip>
            </CheckPermissions>
            <CheckPermissions requiredPermission={["sync.view"]}>
                <Tooltip title="View Details">
                    <div>
                        <Link to={`/reverse-connectors/${reverseConnectorSlug}`}>
                            <SettingsIcon
                                className={clsx(classes.icon, { [classes.font15]: fontSize !== undefined })}
                                fontSize="small"
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            />
                        </Link>
                    </div>
                </Tooltip>
            </CheckPermissions>
            <CheckPermissions requiredPermission={["sync.view"]}>
                <Tooltip title="View Configured Syncs">
                    <Link to={`/reverse-connectors/${reverseConnectorSlug}?tab=configured_syncs`}>
                        <AssessmentOutlinedIcon
                            className={clsx(classes.icon, { [classes.font15]: fontSize !== undefined })}
                            fontSize="small"
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        />
                    </Link>
                </Tooltip>
            </CheckPermissions>
            <CheckPermissions requiredPermission={["sync.view"]}>
                <Tooltip title="View Credentials">
                    <Link to={`/reverse-connectors/${reverseConnectorSlug}`}>
                        <CardMembershipOutlinedIcon
                            className={clsx(classes.icon, { [classes.font15]: fontSize !== undefined })}
                            fontSize="small"
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        />
                    </Link>
                </Tooltip>
            </CheckPermissions>
        </Grid>
    );
}
