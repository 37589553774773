import React from "react";
import "react-circular-progressbar/dist/styles.css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Link, useNavigate } from "react-router-dom";
import { Typography, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import List from "@mui/material/List";
import data_channel_logo_full from "assets/data_channel_fulllogo.png";
import data_channel_logo from "assets/data_channel_logo.png";
import clsx from "clsx";
import usePollingApi from "hooks/usePollingApi";
import { useDispatch, useSelector } from "react-redux";
import { ADD_GLOBAL_NOTIFICATION, EMPTY_NOTIFICATIONS, TOGGLE_SIDENAV } from "redux/actionTypes/actionTypes";
import CheckPermissions, { CheckAccountPermissions, checkForPermission } from "services/CheckPermissions";
import OnboardingFlagHOC from "components/Onboarding/common/OnboardingFlagHOC";
import { Button } from "@mui/material";
import { TbArrowBarToLeft, TbArrowBarToRight } from "react-icons/tb";
import { motion } from "framer-motion";
import useMediaQuery from "@mui/material/useMediaQuery";

import MainNavItem from "./MainNavItem";
import SideNavItem from "./SideNavItem";
import PlanMenu from "./PlanMenu";
import Dropdown from "./Dropdown";
import { NavMapper, navData } from "./NavData";
import { checkForFeature } from "services/featureFlagHOC";
import CheckPermissionFunction from "services/CheckPermissionFunction";

const useStyles = makeStyles((theme) => ({
    root: {
        width: 100,
        marginRight: "1rem",
        transitionProperty: "width",
        transitionDuration: "0.5s",
    },
    zoom: {
        zoom: 0.8,
    },
    logo: {
        display: "flex",
        justifyContent: "left",
        marginBottom: 10,
        marginTop: 20,
        marginLeft: 23,
    },
    fullLogo: {
        width: "100%",
        height: "auto",
        maxWidth: "200px",
        maxHeight: "153px",
        objectFit: "contain",
        padding: "2px 3px 2px 0px",
        marginLeft: "-5px",
    },
    smallLogo: {
        width: "100%",
        height: "auto",
        maxWidth: "58px",
        maxHeight: "58px",
        objectFit: "contain",
        padding: "10px 0px 15px 0px",
    },
    iconContainer: {
        justifyContent: "center",
    },
    drawerOpen: {
        width: "240px",
        transitionProperty: "width",
        transitionDuration: "0.5s",
        boxShadow:
            "10px 3px 40px rgba(0, 0, 0, 0.0282725), 10px 1px 10px rgba(0, 0, 0, 0.0417275), 3px 0.465507px 5.32008px rgba(0, 0, 0, 0.07)",
    },
    drawerClose: {
        width: 75,
        transitionProperty: "width",
        transitionDuration: "0.5s",
        overflowX: "hidden",
        boxShadow:
            "10px 7px 80px rgba(0, 0, 0, 0.0282725), 10px 1px 10px rgba(0, 0, 0, 0.0417275), 3px 0.465507px 5.32008px rgba(0, 0, 0, 0.07)",
    },
    toggleButton: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "31px",
        position: "absolute",
        bottom: "5%",
    },

    relative: {
        background: "#FFFFFF",
        display: "flex",
        flexDirection: "column",

        position: "fixed",
        borderRadius: "8px",
        height: "100%",
    },
    relativeOpen: {
        width: "240px",
        borderRight: "1px solid #D4DCE4",
    },
    openHover: {
        overflowY: "auto",
        position: "absolute",
        left: 240,
        top: "0",
        width: 380,
        maxWidth: 450,
        height: "100%",
        background: "#FBFBFB",
        borderRight: "1px solid #D4DCE4",
        boxShadow:
            "10px 7px 80px rgba(0, 0, 0, 0.0282725), 10px 1px 10px rgba(0, 0, 0, 0.0417275), 5px 0.465507px 5.32008px rgba(0, 0, 0, 0.07)",
    },
    closeHover: {
        overflowY: "auto",
        left: 84,
    },
    OutFocusCheckDiv: {
        position: "absolute",
        opacity: "0",
        width: "100vw",
        height: "100vh",
        left: "620px",
        top: "0",
    },
    OutFocusCheckDivWhenClose: {
        left: "464px",
    },
}));

const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0.5 },
};
/**
 * @component ConditionalWrapper - A wrapper component which wraps children in a wrapper component based on a condition
 * @param {condition} [ A boolean condition based on which wrapper will be used ]
 * @param {wrapper} [ Component to which children will be wrapped ]
 * @param {children} [ all children wrapped in ConditionalWrapper component ]
 * @returns
 */
const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

/**
 * @component Side bar Navigation
 */
export default function Nav(props) {
    const matches = useMediaQuery("(min-height:800px)");
    const open = useSelector((state) => state.uiStates.isSideNavOpen);
    const isOnboarding = useSelector((state) => state.uiStates.isOnboarding);
    const user = useSelector((state) => state.users);
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [hoveredItem, setHoveredItem] = React.useState(null);
    const [openItem, setOpenItem] = React.useState(null);

    const oneDay = 1000 * 60 * 60 * 24;
    const diffTimeFixed = ((new Date(user.user.trial_end).getTime() - new Date().getTime()) / oneDay).toFixed(1);

    let hourDiff;
    if (diffTimeFixed < 1) {
        hourDiff = ((new Date(user.user.trial_end).getTime() - new Date().getTime()) / (1000 * 60 * 60)).toFixed(0);
    }
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setSelectedOption(panel);
        setExpanded(isExpanded ? panel : false);
    };
    const handleDrawer = () => {
        dispatch({ type: TOGGLE_SIDENAV });
    };

    const [isLoading, clusterInfo, setIsPollingStarted] = usePollingApi(
        15000,
        {
            module: "asyncJobs",
            method: "checkAsyncJobStatus",
            apiData: {
                job_name: "create_redshift_cluster",
            },
            returnResult: true,
        },
        true,
        1000000,
        true
    );

    React.useEffect(() => {
        if (clusterInfo && (Object.keys(clusterInfo).length === 0 || clusterInfo?.status === "COMPLETED")) {
            setIsPollingStarted(false);
            dispatch({
                type: EMPTY_NOTIFICATIONS,
                payload: null,
            });
        }
        if (clusterInfo && Object.keys(clusterInfo).length > 0 && clusterInfo?.status === "RUNNING") {
            dispatch({
                type: ADD_GLOBAL_NOTIFICATION,
                payload: {
                    type: "INFO",
                    message: "Redshift cluster creation is in progress. This might take upto 10 mins.",
                },
            });
        }
    }, [clusterInfo]);

    React.useEffect(() => {
        setIsPollingStarted(true);
    }, []);

    // function to get the initial state of the dropdown based on the session storage
    function getInitialState() {
        var selectedOption = sessionStorage.getItem("SelectedOption") || "Account Settings";
        return { selectedOption: selectedOption };
    }

    // function to set the selected option in the session storage
    function setSelectedOption(option) {
        sessionStorage.setItem("SelectedOption", option);
    }

    return (
        <Drawer
            variant="permanent"
            open={open}
            anchor={"left"}
            className={clsx(classes.root, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
                [classes.zoom]: !matches,
            })}
        >
            <div className={clsx(classes.relative, { [classes.relativeOpen]: open })}>
                <Box className={classes.logo}>
                    <Link to="/" color="primary" underline="none">
                        {open ? (
                            <img className={classes.fullLogo} src={data_channel_logo_full} alt="DataChannel Logo" />
                        ) : (
                            <img className={classes.smallLogo} src={data_channel_logo} alt="DataChannel Logo" />
                        )}
                    </Link>
                </Box>
                <List style={{ height: "100%", maxHeight: "60%", overflowY: "auto", overflowX: "hidden" }}>
                    {navData.map((data, index) => (
                        <OnboardingFlagHOC isOnboarding={isOnboarding} link={data.link}>
                            <ConditionalWrapper
                                condition={data?.requiredPermission.length > 0}
                                wrapper={(children) =>
                                    data?.account_level_permission ? (
                                        <CheckAccountPermissions requiredPermission={data?.requiredPermission}>
                                            {children}
                                        </CheckAccountPermissions>
                                    ) : (
                                        <CheckPermissions requiredPermission={data?.requiredPermission}>
                                            {children}
                                        </CheckPermissions>
                                    )
                                }
                            >
                                {data.link ? (
                                    <Link to={data?.link} color="primary" underline="none">
                                        <MainNavItem
                                            data={data}
                                            open={open}
                                            setHoveredItem={setHoveredItem}
                                            setOpenItem={setOpenItem}
                                            hoveredItem={hoveredItem}
                                            openItem={openItem}
                                        />
                                    </Link>
                                ) : (
                                    <MainNavItem
                                        data={data}
                                        open={open}
                                        setHoveredItem={setHoveredItem}
                                        setOpenItem={setOpenItem}
                                        hoveredItem={hoveredItem}
                                        openItem={openItem}
                                    />
                                )}
                            </ConditionalWrapper>
                        </OnboardingFlagHOC>
                    ))}
                </List>
                {NavMapper[openItem] !== undefined && openItem !== null ? (
                    <>
                        <motion.div
                            className={clsx(classes.openHover, {
                                [classes.closeHover]: !open,
                            })}
                            initial="hidden"
                            animate="visible"
                            variants={variants}
                            onMouseLeave={() => setOpenItem(null)}
                        >
                            <span
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    borderBottom: "1px solid #D4DCE4",
                                }}
                            >
                                <Typography
                                    style={{
                                        fontFamily: "Montserrat",
                                        fontWeight: 600,
                                        fontSize: "18px",
                                        width: "100%",
                                        padding: "14px",
                                        paddingBottom: "10px",
                                    }}
                                    variant="h2"
                                >
                                    {openItem}
                                </Typography>
                                <ListItemIcon
                                    classes={classes.iconContainer}
                                    sx={{
                                        justifyContent: "center",
                                    }}
                                >
                                    {React.createElement(NavMapper[openItem].icon)}
                                </ListItemIcon>
                            </span>
                            <List
                                style={{
                                    margin: "1rem 0.8rem",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 1,
                                }}
                            >
                                {NavMapper[openItem].hasDropdown
                                    ? Object.keys(NavMapper[openItem].data).map((key) => {
                                          if (
                                              (NavMapper[openItem].data[key][0].role !== "none" &&
                                                  NavMapper[openItem].data[key][0].role !== user.user.account_role) ||
                                              (NavMapper[openItem].data[key][0].selfServeOnly === true &&
                                                  user.user.plan_name === "Unlimited")
                                          )
                                              return null;
                                          if (
                                              !checkForFeature(
                                                  user.user.subscription_meta_data?.features_flags,
                                                  NavMapper[openItem].data[key][0].feature
                                              ) ||
                                              !checkForPermission(
                                                  NavMapper[openItem].data[key][0].account_level_permission
                                                      ? user.user.account_permissions
                                                      : user.user.permissions,
                                                  NavMapper[openItem].data[key][0].permission
                                              )
                                          )
                                              return null;
                                          return (
                                              <Dropdown
                                                  keyName={key}
                                                  items={NavMapper[openItem].data[key].slice(1)}
                                                  open={true}
                                                  close={false}
                                                  expanded={!expanded ? getInitialState().selectedOption : expanded}
                                                  handleChange={handleChange}
                                              />
                                          );
                                      })
                                    : NavMapper[openItem].data.map((data) => (
                                          <SideNavItem
                                              name={data?.name}
                                              subtitle={data?.subtitle}
                                              redirectLink={data?.link}
                                              userFeatures={user.user.subscription_meta_data?.features_flags}
                                              requiredFeatures={data?.features}
                                              requiredPermission={data.permission}
                                              accountPermission={data?.account_permission}
                                              account_level_permission={data?.account_level_permission}
                                          />
                                      ))}
                            </List>
                        </motion.div>
                        <div
                            onClick={() => setOpenItem(null)}
                            className={clsx(classes.OutFocusCheckDiv, {
                                [classes.OutFocusCheckDivWhenClose]: !open,
                            })}
                        />
                    </>
                ) : null}

                <Grid item xs={12} style={{ position: "absolute", bottom: "8%", width: "100%" }}>
                    {isOnboarding &&
                        (open ? (
                            <Grid container item xs={12} alignItems="baseline" justifyContent="center">
                                <Button variant="contained" onClick={() => navigate("/")}>
                                    Go back to wizard
                                </Button>
                            </Grid>
                        ) : (
                            <Grid container item xs={12} alignItems="baseline" justifyContent="center">
                                <Button variant="contained" onClick={() => navigate("/")}>
                                    <Typography style={{ fontSize: 9 }}>To wizard</Typography>
                                </Button>
                            </Grid>
                        ))}
                    <Grid container item xs={12} alignItems="baseline" justifyContent="flex-end">
                        {/* {open && ( */}
                        <Grid item xs={12} style={{ width: "100%" }}>
                            <PlanMenu planName="Free Plan" isRowUsed={true} linkText="Manage Plans" isOpen={open} />
                        </Grid>
                        {/* )} */}
                    </Grid>
                </Grid>
                <div className={classes.toggleButton}>
                    {open ? (
                        <Button
                            style={{
                                backgroundColor: "#fff",
                                width: "90%",
                                height: "100%",
                                fontSize: "12px",
                                color: "#939DA9",
                            }}
                            onClick={handleDrawer}
                        >
                            <TbArrowBarToLeft
                                style={{
                                    fontSize: "15px",
                                    marginRight: "5px",
                                }}
                            />{" "}
                            Collapse Side Bar
                        </Button>
                    ) : (
                        <Button
                            style={{ backgroundColor: "#fff", width: "90%", height: "100%", color: "#939DA9" }}
                            onClick={handleDrawer}
                        >
                            <TbArrowBarToRight
                                style={{
                                    fontSize: "16px",
                                    marginRight: "5px",
                                }}
                            />
                        </Button>
                    )}
                </div>
            </div>
        </Drawer>
    );
}
