import { FETCH_LLM_CREDENTIALS, FETCH_LLM_CREDENTIALS_IS_LOADING } from "../actionTypes/actionTypes";

const initialState = {
    llmCredentials: [],
    llmCredentialsIsLoading: false,
};

export const externalEntitiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_LLM_CREDENTIALS:
            return {
                ...state,
                llmCredentials: action.payload,
            };
        case FETCH_LLM_CREDENTIALS_IS_LOADING:
            return {
                ...state,
                llmCredentialsIsLoading: action.payload,
            };
        default:
            return state;
    }
};
