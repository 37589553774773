import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import SideNavItem from "./SideNavItem";

import clsx from "clsx";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import { checkForFeature } from "services/featureFlagHOC";

const useStyles = makeStyles((theme) => ({
    itemName: {
        color: "#2C2C2C",
        cursor: "pointer",
        padding: "0px",
        marginBottom: "10px",
        "&:hover": {
            borderRadius: 8,
            backgroundColor: "#FFF6EF",
            color: "#FD9567",
        },
    },
    removeBefore: {
        "&::before": {
            display: "none",
        },
    },
    icon: {
        color: "#FD9567",
        fill: "#FD9567",
    },
    onExpand: {
        borderRadius: 8,
        backgroundColor: "#FFF6EF",
        color: "#FD9567",
    },
}));

/**
 * This component is used to display the dropdown items
 *
 * @component
 * @param {String} keyName - The name of the dropdown
 * @param {Array} items - The items to be displayed
 * @param {String} expanded - The state of the dropdown
 * @param {Function} handleChange - The function to handle change
 * @returns {Component}
 *
 */
function Dropdown({ keyName, items, expanded, handleChange }) {
    const classes = useStyles();
    const [isHovered, setIsHovered] = useState(false);

    const account_role = useSelector((state) => state.users?.user?.account_role);
    const user_features = useSelector((state) => state.users?.user?.subscription_meta_data.features_flags);
    const plan_name = useSelector((state) => state.users?.user?.plan_name);

    if (plan_name === "Unlimited" && keyName === "Subscription Management") {
        return null;
    }

    return (
        <>
            <Accordion
                expanded={expanded === keyName}
                onChange={handleChange(keyName)}
                style={{ boxShadow: "none", border: "none", backgroundColor: "inherit" }}
                disableGutters
                className={classes.removeBefore}
            >
                <AccordionSummary
                    className={clsx(classes.itemName, { [classes.onExpand]: expanded === keyName })}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    expandIcon={
                        <ExpandMoreIcon
                            className={clsx(
                                classes.icon,
                                expanded === keyName || isHovered || sessionStorage.getItem("SelectedOption") === keyName
                            )}
                        />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ padding: "0px" }}
                >
                    <Typography
                        style={{
                            fontFamily: "Montserrat",
                            fontWeight: 600,
                            fontSize: "16px",
                            width: "90%",
                            padding: "5px 5px 5px 16px",
                            color: "inherit",
                        }}
                    >
                        {keyName}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: "0px" }}>
                    {items.map((data, index) => {
                        const shouldRender = data?.role === account_role || data.role === undefined;
                        return shouldRender ? (
                            <SideNavItem
                                name={data?.name}
                                subtitle={data?.subtitle}
                                redirectLink={data?.link}
                                key={index}
                                requiredPermission={data?.permission}
                                accountPermission={data?.account_permission}
                                account_level_permission={data?.account_level_permission}
                                userFeatures={user_features}
                                requiredFeatures={data?.features}
                            />
                        ) : null;
                    })}
                </AccordionDetails>
            </Accordion>
        </>
    );
}

export default Dropdown;
