import React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Config from "constants/config";
import { Field, Form, Formik, useField } from "formik";
import { Chip, CircularProgress, FormGroup, MenuItem, Select } from "@mui/material";
import { object, string, email, array } from "yup";
import { addUser, updateUser } from "../../../../../services/Api/api";
import successSnackbar from "../../../../../utils/successSnackbar";
import { useDispatch, useSelector } from "react-redux";
import { getRoles } from "services/Api/api";
import { HttpErrorHandler } from "utils/ErrorHandlers/HttpErrorHandler";
import useApi from "hooks/useApi";
import { fetchAccountWorkspaces } from "redux/actions/workspaceActions";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import makeStyles from "@mui/styles/makeStyles";
import { CheckAccountPermissionFunction } from "services/CheckPermissionFunction";

const useStyles = makeStyles((theme) => ({
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        height: 17,
        fontSize: 9,
        marginRight: 5,
        marginTop: 1,
    },
    inputLabel: {
        position: "absolute",
        left: 10,
        top: -10,
        paddingLeft: 2,
        paddingBottom: 3,
        backgroundColor: "#FFFFFF",
        paddingRight: 45,
        zIndex: 1000,
        fontSize: 10,
    },
    select: {
        margin: "13px 0px",
    },
}));

const CustomSelect = (props) => {
    const classes = useStyles();
    const [field] = useField({
        name: props.name,
        type: "select",
        value: props.value,
    });
    return (
        <FormControl variant="standard" fullWidth className={classes.select}>
            <InputLabel className={classes.inputLabel}>{props.label}</InputLabel>
            <Select variant="standard" {...props} {...field} onChange={props.onChange}>
                {props.children}
            </Select>
        </FormControl>
    );
};
const UserForm = (props) => {
    const dispatch = useDispatch();
    const [roles, setRoles] = React.useState([]);
    const [rolesIsLoading, setRolesIsLoading] = React.useState(false);
    const [, addUserIsLoading, addUserHook] = useApi();
    const account_role = useSelector((state) => state.users?.user?.account_role);
    const workspaces = useSelector((state) => state.workspaces.accountWorkspaces);
    const workspacesIsLoading = useSelector((state) => state.workspaces.accountWorkspacesIsLoading);
    const user_workspaces = useSelector((state) => state.users?.user?.workspaces);
    const [allRoles, setAllRoles] = React.useState([]);
    const [disableUpdate, setDisableUpdate] = React.useState(false);
    React.useEffect(() => {
        setRolesIsLoading(true);
        getRoles()
            .then((res) => {
                const roles_clone = [...res.data.data];
                setAllRoles(res.data.data);
                if (account_role !== "Super-Admin") {
                    var elementToRemove = "Super-Admin";
                    var index = roles_clone.findIndex(function (item) {
                        return item.name === elementToRemove;
                    });

                    if (index !== -1) {
                        roles_clone.splice(index, 1);
                    }
                }
                setRoles(roles_clone);
                setRolesIsLoading(false);
            })
            .catch((e) => {
                setRolesIsLoading(false);
            });
        if (account_role == "Super-Admin") {
            dispatch(fetchAccountWorkspaces());
        }
    }, []);
    const [initialValues, setInitialValues] = React.useState({
        name: "",
        role: "",
        email: "",
        password: "",
        workspace_id: [],
    });
    React.useEffect(() => {
        if (props.edit !== null && props.edit === true) {
            if (roles) {
                console.log(props.user.role_id);
                let role = allRoles.find((role) => role.id === props.user.role_id);
                if (role) {
                    console.log(role.name);
                    console.log(account_role);
                    if (role.name === "Super-Admin" && account_role !== "Super-Admin") {
                        setDisableUpdate(true);
                    }
                }
            }
        }
    }, [roles]);
    const handleSubmit = async (values, helpers) => {
        let finalObject = {};
        if (props.edit !== null && props.edit === true ? true : false) {
            finalObject.id = props.user.id;
            finalObject.name = values.name;
            finalObject.role = values.role;
            updateUser(finalObject)
                .then((res) => {
                    successSnackbar(dispatch, "User Updated Successfully");
                    window.location.href = `${Config.frontendUrl}settings`;
                    helpers.setSubmitting(false);
                })
                .catch((e) => {
                    console.log(e);
                    HttpErrorHandler(dispatch, e?.response?.data?.message);
                    helpers.setSubmitting(false);
                });
        } else {
            // finalObject.name = values.name;
            // finalObject.role = values.role;
            // finalObject.email = values.email;
            // finalObject.password = values.password;
            // addUser(finalObject)
            // 	.then((res) => {
            // 		successSnackbar(dispatch, "User Updated Successfully");
            // 		helpers.setSubmitting(false);
            // 		window.location.href = `${Config.frontendUrl}settings`;
            // 	})
            // 	.catch((e) => {
            // 		HttpErrorHandler(dispatch, e?.response?.data?.message);
            // 		helpers.setSubmitting(false);
            // 	});
            finalObject.email = values.email;
            finalObject.role = values.role;
            finalObject.workspace_id = values.workspace_id;

            try {
                await addUserHook({
                    module: "user",
                    method: "addUser",
                    successToast: "Email Sent To given Email",
                    apiData: {
                        data: finalObject,
                    },
                    returnResult: true,
                    throwError: true,
                });
            } catch (e) {
                console.log(e);
                HttpErrorHandler(dispatch, e.response?.data?.message);
            }
        }
    };
    let validationSchema = {};
    if (props.edit !== null && props.edit === true) {
        validationSchema = object({
            email: string().required().email(),
            password: string().required().min(6),
            name: string().required(),
            role: string().required(),
        });
    } else {
        validationSchema = object({
            email: string().required().email(),
            role: string().required(),
            workspace_id: array().min(1),
        });
    }
    if (props.edit !== null && props.edit === true && initialValues.name === "") {
        setInitialValues({
            name: props.user.name,
            role: props.user.role_id,
            email: props.user.email,
            password: "abc123",
        });
    }

    React.useEffect(() => {
        if (user_workspaces?.length === 1 && initialValues.workspace_id?.length === 0) {
            setInitialValues({
                workspace_id: [user_workspaces[0].workspace_id],
            });
        }
    }, [user_workspaces]);
    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={(values, helpers) => handleSubmit(values, helpers)}
            enableReinitialize={true}
        >
            {({ values, errors, isSubmitting, isValidating, touched }) => (
                <Form>
                    {props.edit !== null && props.edit === true && (
                        <FormGroup>
                            <Field
                                error={touched.name && errors.hasOwnProperty("name")}
                                name="name"
                                as={TextField}
                                label="Name"
                                disabled={disableUpdate}
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                helperText={touched.name && errors?.name}
                            />
                        </FormGroup>
                    )}
                    {rolesIsLoading ? (
                        <CircularProgress size={30} />
                    ) : (
                        <FormGroup>
                            <Field
                                error={touched.role && errors.hasOwnProperty("role")}
                                name="role"
                                as={TextField}
                                select
                                label="User Role"
                                variant="outlined"
                                disabled={disableUpdate}
                                fullWidth
                                margin="dense"
                                helperText={touched.role && errors?.role}
                            >
                                {roles.map((el, i) => (
                                    <MenuItem key={i} value={el.id}>
                                        {el.name}{" "}
                                        {el.role_type === "Custom" && (
                                            <Chip label={"Custom Role"} style={{ marginLeft: 10 }}></Chip>
                                        )}
                                    </MenuItem>
                                ))}
                                {/* <MenuItem value={1}>Admin</MenuItem>
                            <MenuItem value={2}>User</MenuItem> */}
                            </Field>
                        </FormGroup>
                    )}
                    <FormGroup>
                        <Field
                            error={touched.email && errors.hasOwnProperty("email")}
                            name="email"
                            as={TextField}
                            label="Email Id"
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            disabled={disableUpdate || (props.edit !== null && props.edit === true ? true : false)}
                            helperText={touched.email && errors?.email}
                        />
                    </FormGroup>
                    {props.edit !== null && props.edit === true && (
                        <FormGroup>
                            <Field
                                error={touched.password && errors.hasOwnProperty("password")}
                                name="password"
                                as={TextField}
                                id="outlined-basic"
                                label="Password"
                                variant="outlined"
                                fullWidth
                                type="password"
                                margin="dense"
                                disabled={disableUpdate || (props.edit !== null && props.edit === true ? true : false)}
                                helperText={touched.password && errors?.password}
                            />
                        </FormGroup>
                    )}
                    {!props.edit && (
                        <FormGroup>
                            <Field
                                error={touched.workspace_id && errors.hasOwnProperty("workspace_id")}
                                name="workspace_id"
                                as={CustomSelect}
                                multiple
                                label="Workspaces"
                                variant="outlined"
                                disabled={disableUpdate}
                                fullWidth
                                margin="dense"
                                helperText={touched.workspace_id && errors?.workspace_id}
                            >
                                {CheckAccountPermissionFunction(["workspace.view"])
                                    ? user_workspaces?.map((el, i) => (
                                          <MenuItem key={i} value={el.workspace_id}>
                                              {el.name}
                                          </MenuItem>
                                      ))
                                    : workspaces?.map((el, i) => (
                                          <MenuItem key={i} value={el.workspace_id}>
                                              {el.name}
                                          </MenuItem>
                                      ))}
                            </Field>
                        </FormGroup>
                    )}

                    <Button
                        type="submit"
                        disabled={isSubmitting || isValidating || addUserIsLoading}
                        variant="contained"
                        color="primary"
                        fullWidth
                    >
                        {props.edit !== null && props.edit === true ? "Update User" : "Add User"}
                    </Button>
                    {props.click && (
                        <Button
                            disabled={isSubmitting || isValidating || addUserIsLoading}
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={props.click}
                        >
                            Cancel
                        </Button>
                    )}
                </Form>
            )}
        </Formik>
    );
};

export default UserForm;
